jQuery(document).ready(function ($) {

    function initSectionSliders() {
        let sectionSliders = $('.section-slider .slider');
        if (sectionSliders) {

            sectionSliders.each((idx, slider) => {

                $(slider).addClass('owl-carousel');
                let perView = $(slider).data('per_page') ? $(slider).data('per_page') : 1;
                let navigation = !!$(slider).data('navigation');
                let dots = !!$(slider).data('dots');

                $(slider).owlCarousel({
                    loop: true,
                    dots: dots,
                    //dotsContainer: '#myDots',
                    nav: navigation,
                    items: 1,
                    stagePadding: 0,
                    lazyLoad: true,
                    responsiveBaseElement: 'main',
                    margin: 0,
                    autoplay: true,
                    autoPlaySpeed: 3000,
                    autoplayHoverPause: true,
                    autoHeight:true
                });

            })
        }
    }

    initSectionSliders();
    window.addEventListener('resize', initSectionSliders);


});
