const isOpenClass = "modal-is-open";
const openingClass = "modal-is-opening";
const closingClass = "modal-is-closing";
const animationDuration = 400; // ms
let visibleModal = null;

const isModalOpen = (modal) => {
    return modal.hasAttribute("open") && modal.getAttribute("open") != "false" ? true : false;
};

const isScrollbarVisible = () => {
    return document.body.scrollHeight > screen.height;
};

const getScrollbarWidth = () => {
    // Creating invisible container
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll"; // forcing scrollbar to appear
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement("div");
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
};

const openModal = (modal) => {
    if (isScrollbarVisible()) {
        document.documentElement.style.setProperty("--scrollbar-width", `${getScrollbarWidth()}px`);
    }
    document.documentElement.classList.add(isOpenClass, openingClass);
    setTimeout(() => {
        visibleModal = modal;
        document.documentElement.classList.remove(openingClass);
    }, animationDuration);
    modal.setAttribute("open", true);
};
const toggleModal = (event) => {
    event.preventDefault();
    const targetModal = event.currentTarget.getAttribute("data-target")
        ? event.currentTarget.getAttribute("data-target")
        : 'contact-modal';
    const modal = document.getElementById(targetModal);
    typeof modal != "undefined" && modal != null && isModalOpen(modal)
        ? closeModal(modal)
        : openModal(modal);
};

const closeModal = (modal) => {
    visibleModal = null;
    document.documentElement.classList.add(closingClass);
    setTimeout(() => {
        document.documentElement.classList.remove(closingClass, isOpenClass);
        document.documentElement.style.removeProperty("--scrollbar-width");
        modal.removeAttribute("open");
    }, animationDuration);
};

let modalTriggers = document.querySelectorAll('.open-modal');
if (modalTriggers) {
    modalTriggers.forEach((modalTrigger) => {
        modalTrigger.addEventListener('click', toggleModal);
    })

    document.querySelectorAll('.close-modal').forEach((closeBtn) => {
        closeBtn.addEventListener('click', toggleModal);
    });
}


