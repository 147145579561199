if (document.querySelector('.scroll-down')) {
    document.querySelector('.scroll-down').addEventListener('click', function () {
        const y = document.getElementById('scrolly').getBoundingClientRect().top + window.scrollY;
        window.scroll({
            top: y - 350,
            behavior: 'smooth'
        });
        this.style.display = 'none';
    })

}

var idx = 1;
const totalWords = document.querySelectorAll('.change_text span').length;
function scrollText() {

    if (idx > totalWords) {
        idx = 1;
    }
    jQuery('.change_text span').removeClass('active').hide();
    jQuery('.change_text span:nth-of-type(' + idx +')').show().addClass('active');
    idx++;
    setTimeout(() => {
        scrollText();

    }, 2000);
}
scrollText();

jQuery(document).ready(function ($) {
// Team slider
    $('.team .owl-carousel').owlCarousel({
        loop: true,
        nav: true,
        dots: false,
        items: 3,
        stagePadding: 24,
        lazyLoad: false,
        responsiveClass: false,
        responsiveBaseElement: 'body',
        margin: 24,
        responsive: {
            0: {
                items: 3.2
            },
            768: {
                items: 5.2
            },
            1600: {
                items: 6.2
            }
        }
    });
});
