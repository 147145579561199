import scrollama from "scrollama";

const scroller = scrollama();

const scrolly = document.getElementById("scrolly");

if (scrolly) {
    var figure = scrolly.querySelector("figure");
    var article = scrolly.querySelector("article");
    var steps = article.querySelectorAll(".step");
    init();
}

function handleResize() {
    let stepH = Math.floor(window.innerHeight * 0.5);
    steps.forEach((step) => {
        step.style.height = stepH + "px";
    })

    let figureHeight = window.innerHeight / 2;
    let figureMarginTop = (window.innerHeight - figureHeight) / 2;

    figure.style.height = figureHeight + "px";
    figure.style.top = figureMarginTop + "px";

    scroller.resize();
}

// scrollama event handlers
function handleStepEnter(response) {

    // let theme = (response.index === 1) ? 'dark' : 'light';
    // document.querySelector("html").setAttribute('data-theme', theme);

    scrolly.querySelectorAll('.step').forEach((step, i) => {
        if (i === response.index) {
            step.classList.add('active');
        } else {
            step.classList.remove('active');
        }
    })

    let imgSrc = steps[response.index].dataset.img
    figure.querySelector('img').src = imgSrc;
    figure.style.display = 'block';

}

function handleStepProgress(response) {
    let progress = response.progress.toFixed(2) * 300;
    //figure.querySelector('img').style['clip-path'] = 'inset(0px 0px ' + (100 - progress) + '%)';
    figure.querySelector('img').style['opacity'] = progress + '%';
}

function init() {

    handleResize();

    scroller
        .setup({
            step: "#scrolly article .step",
            offset: 0.5,
            debug: false,
            progress: true
        })
        .onStepProgress(handleStepProgress)
        .onStepEnter(handleStepEnter);
}
