function animateValue(obj) {

    let end = parseInt(obj.dataset.end);

    let current = 0;
    let increment = parseInt(obj.dataset.end / 10);

    let stepTime = 80;

    let timer = setInterval(function() {
        current += increment;
        obj.innerHTML = current.toString();
        if (current > end) {
            obj.innerHTML = end.toLocaleString('et-EE');
            clearInterval(timer);
        }
    }, stepTime);
}

const scrollingNumbers = [].slice.call(document.querySelectorAll(".countable"));

const numbersObserver = new IntersectionObserver(function(entries, observer) {
    entries.forEach(function(entry) {
        if (entry.isIntersecting) {
            animateValue(entry.target);
            numbersObserver.unobserve(entry.target);
        }
    });
});

scrollingNumbers.forEach(function(element) {
    numbersObserver.observe(element);
})
